/** @jsx jsx */
import { Link, navigate } from 'gatsby';
import { Container, Flex, Box, Heading, jsx, Button, IconButton, Text } from 'theme-ui';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { keyframes } from '@emotion/react';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import RedSticker from '~/assets/images/red_sticker.svg';

const Swing = keyframes({
  from: { transform: 'rotateZ(-5deg)' },
  to: { transform: 'rotateZ(5deg)' },
});

const HeroSection = ({ hero }) => {
  return (
    <Container
      variant="container"
      sx={{
        position: 'relative',
        pb: ['2rem', '6rem'],
        px: ['1.6rem'],
        overflow: 'visible',
        mt: '4rem',
      }}
    >
      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          transition: 'all 0.3s ease-in-out',
          top: ['10.6rem', null, null],
          left: ['-5rem', null, '-12%', 0],
          width: ['11rem', null, '20rem'],
          height: ['15.4rem', null, '27rem'],
          transformOrigin: 'top center',
          animation: `${Swing} 2s ease-in-out alternate infinite`,
        }}
      >
        <StaticImage
          placeholder="none"
          src="../../../../../assets/images/Parachute-Box.png"
          objectFit="contain"
        />
      </Box>

      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          top: [0, null],
          right: [0, null, '-2%', '8%'],
          width: ['10rem', null, '14rem'],
          height: ['20rem', null, '23rem'],
          animation: `${Swing} 2s ease-in-out alternate-reverse infinite`,
        }}
      >
        <StaticImage placeholder="none" src="../../../../../assets/images/Parachute-Tampons.png" />
      </Box>

      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          bottom: ['8%', null],
          left: [0, null, '-2%', '8%'],
          width: ['10rem', null, '14rem'],
          height: ['16rem', null, '22rem'],
          animation: `${Swing} 1.6s ease-in-out alternate-reverse infinite`,
        }}
      >
        <StaticImage placeholder="none" src="../../../../../assets/images/Parachute-Pads.png" />
      </Box>

      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          top: ['60%'],
          left: ['95%', null, null, '90%'],
          width: ['11rem', null, '20rem'],
          height: ['15rem', null, '27rem'],
          animation: `${Swing} 1.5s ease-in-out alternate infinite`,
        }}
      >
        <StaticImage placeholder="none" src="../../../../../assets/images/Parachute-Box.png" />
      </Box>

      <Link to={hero.ctaPath}>
        <Heading
          as="h1"
          variant="leadHeading"
          sx={{
            position: 'relative',
            fontSize: [16, '3.6rem', '4.8rem'],
            textAlign: 'center',
            maxWidth: [null, '60rem', '76rem'],
            mx: [null, 'auto'],
            zIndex: 1,
          }}
        >
          {hero.heading.text}
        </Heading>
      </Link>

      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'center',
          width: ['32.5rem', '52.6rem', '72.6rem'],
          mx: 'auto',
          pt: ['4.8rem'],
        }}
      >
        <Box
          as={Link}
          to={hero.ctaPath}
          onClick={() => navigate(hero.ctaPath)}
          sx={{
            position: 'relative',
            width: '100%',
            height: ['32.5rem', '35rem', '48.4rem'],
            backgroundColor: 'white',
            borderRadius: '6px',
            mx: 'auto',
            mb: ['2rem', '5rem'],
            '::before': {
              content: '""',
              width: '100%',
              height: '100%',
              backgroundColor: 'alizarinCrimson',
              position: 'absolute',
              top: '-8px',
              right: '-8px',
              borderRadius: '6px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'alizarinCrimson',
              borderRadius: '6px',
            }}
          >
            <GatsbyImage
              image={hero.image.gatsbyImageData}
              loading="eager"
              style={{
                width: '100%',
                height: '100%',
              }}
              imgStyle={{ borderRadius: '6px', pointerEvents: 'none' }}
            />
          </Box>
        </Box>

        <Button
          as={Link}
          to={hero.ctaPath}
          sx={{
            variant: 'buttons.ctaHighlight',
            position: 'absolute',
            top: ['2.5rem'],
            left: ['50%'],
            right: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {hero.ctaText}
          <ArrowRight />
        </Button>

        <IconButton
          onClick={() => {
            const bestSellersBoxContainer = document.getElementById('bestSellers');
            bestSellersBoxContainer &&
              bestSellersBoxContainer.scrollIntoView({ behavior: 'smooth' });
          }}
          sx={{
            position: 'absolute',
            top: ['25%'],
            left: 0,
            transform: 'translateX(-50%)',
            width: [0, '25%', '25%'],
            display: ['none', 'inline-flex', 'inline-flex'],
          }}
        >
          <RedSticker />
          <Text
            sx={{
              position: 'absolute',
              top: ['50%'],
              left: ['50%'],
              transform: 'translate(-50%, -50%)',
              color: 'canary',
              textTransform: 'uppercase',
              fontSize: [0, '1.1rem', '1.5rem'],
              svg: {
                transform: 'rotate(90deg)',
                width: [0, '1.5rem', '2.5rem'],
                path: {
                  stroke: 'canary',
                },
              },
            }}
          >
            Best <br /> Sellers
            <Box sx={{ width: '100%', mt: [0, '0.5rem', '1.5rem'] }}>
              <ArrowRight />
            </Box>
          </Text>
        </IconButton>
      </Flex>
    </Container>
  );
};

export default HeroSection;

import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box } from 'theme-ui';
import Slider from 'react-slick';
import { HomeScreenBoxcard } from './HomeScreenBoxcard';
import useWindowSize from '~/hooks/components/use-window-size';
import ArrowNext from '~/assets/images/icons/Arrow-Review-Next.svg';
import ArrowPrev from '~/assets/images/icons/Arrow-Review-Prev.svg';

export const HomeScreenBoxSection = ({
  boxList,
  subDiscount,
  buttonIsLoading,
  bundleButtonLoading,
  clickedButton,
  setShowOptions,
  clickHandler,
  buildABoxImage,
}) => {
  const { width } = useWindowSize();
  const widthRef = useRef();
  const [showSlider, setShowSlider] = useState(false);

  const slideWidth = width > 1600 ? 320 : 310;
  // Show a max of 4 slides and min of 1.1 slides
  // check width of the container (widthRef) or the width of screen and divide it with slideWidth to get the posible slides.
  const slidesToShow = Math.min(
    Math.max(Math.min(width, widthRef?.current?.clientWidth || width) / slideWidth, 1.1),
    4
  );
  const bundlesSKUs = ['travel-20ct', 'august-sample-longnight', 'august-sample'];

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setShowSlider(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      ref={widthRef}
      sx={{
        maxWidth: '130rem',
        width: '100%',
        height: '45rem',
        '.slick-slide': {
          pt: 'min(3rem, 1vw)',
        },
        '.slick-slide > div': {
          display: 'flex',
          ml: [0, 0, 0],
          mr: '-2rem !important',
          justifyContent: ['flex-start', 'flex-start', 'flex-start'],
          maxWidth: '32.5rem',
        },
        '.slick-dots': {
          bottom: '-6rem',
          'li button:before': {
            fontSize: '1.15rem',
          },
          'li.slick-active button::before, li button:hover': {
            color: 'alizarinCrimson',
          },
        },
        '.slick-list': {
          mr: '0 !important',
        },
        '.slick-slider .slick-list': {
          minHeight: '45rem',
          maxHeight: '45rem',
        },
        '.slick-prev:before, .slick-next:before': {
          fontSize: '4rem',
          color: 'alizarinCrimson',
        },
        '.slick-prev': {
          width: '5rem',
          height: '5rem',
          left: [0, 0, '-6.5rem'],
        },
        '.slick-next': {
          width: '5rem',
          height: '5rem',
          right: [0, 0, '-6.5rem'],
        },
      }}
      id="bestSeller"
    >
      {showSlider && (
        <Slider
          arrows={width > 1024}
          cssEase="ease-in-out"
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          infinite={false}
          dots={width < 1024}
          nextArrow={<ArrowNext />}
          prevArrow={<ArrowPrev />}
        >
          <HomeScreenBoxcard
            title="Build A Box"
            gatsbyImageData={buildABoxImage.gatsbyImageData}
            description=""
            buttonIsLoading={null}
            price="From"
            subPrice={12.78}
            clickedButton={clickedButton}
            setShowOptions={setShowOptions}
            buildABox
          />
          {boxList.map((box) => {
            const title = box.title ?? box.displayName;
            const boxPrice = box.displayPrice ?? box.price;
            const subPrice = boxPrice - boxPrice * subDiscount;
            const description = box.productDetails;
            const imagesData = box?.boxImage?.gatsbyImageData ?? box.mainImage.gatsbyImageData;
            const isBundle = bundlesSKUs.includes(box.sku);

            return (
              <HomeScreenBoxcard
                key={title + box.order}
                id={title + box.order}
                title={title}
                gatsbyImageData={imagesData}
                description={description}
                order={title}
                box={box}
                buttonIsLoading={isBundle ? bundleButtonLoading : buttonIsLoading}
                price={boxPrice}
                subPrice={subPrice}
                clickHandler={clickHandler}
                clickedButton={clickedButton}
                setShowOptions={setShowOptions}
                hasPDP={isBundle}
                isBundle={isBundle}
              />
            );
          })}
        </Slider>
      )}
    </Box>
  );
};

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { useEffect } from 'react';
import Layout from '~/components/Layout';
import SplashPage from '~/components/Pages/Splash';
import Metadata from '~/components/Metadata';
import StructuredData from '~/components/Generic/StructuredData';

const Splash = ({ data: { page } }) => {
  return (
    <Layout>
      <Metadata />
      <StructuredData>
        {{
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: 'August',
          url: 'https://itsaugust.co',
          logo: '//images.ctfassets.net/imbw84wm8wpn/31K0hOIYLUa55HXPJcEueA/d79e3ce4165349b2e827237dae62f4a2/august-logo-crimson.png',
          sameAs: [
            'https://tiktok.com/@itsaugustco',
            'https://snapchat.com/itsaugustco',
            'https://twitter.com/itsaugustco',
            'https://facebook.com/itsaugustco',
            'https://instagram.com/itsaugust',
          ],
        }}
      </StructuredData>
      <SplashPage page={page} />
    </Layout>
  );
};

export default Splash;

export const query = graphql`
  query SplashPage {
    page: contentfulPage(slug: { eq: "landing" }) {
      hero {
        heading {
          text: heading
        }
        image {
          gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 1200)
          title
          description
        }
        ctaText
        ctaPath
      }

      sections {
        name
        description {
          description
        }
        padsDonated
        images {
          gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1600)
          file {
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        video {
          file {
            url
            fileName
            contentType
          }
        }
        marqueeImages {
          image {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1600)
            description
            title
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          altText
          link
          label {
            raw
          }
        }
        heading {
          raw
        }
        subheading {
          raw
        }
        copy {
          raw
        }
        media {
          __typename
          ...QuoteDetailsFragment
          ...ContentCardFragment
          ...ArticleCardFragment
          ...ProductCardFragment
          ...CollectionCardFragment
        }
        buttonLink
        buttonText {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        detailSections {
          title
          body {
            id
            body
            childMarkdownRemark {
              id
              rawMarkdownBody
              html
            }
          }
          displayName
          mainImage {
            gatsbyImageData(quality: 100, layout: CONSTRAINED, aspectRatio: 1, width: 438)
            url
            description
            title
          }
          mainVideo {
            url
            description
            title
          }
          sectionIcon {
            gatsbyImageData(quality: 100, layout: CONSTRAINED, aspectRatio: 1, width: 438)
            url
            description
            title
          }
        }
      }
    }
  }
`;

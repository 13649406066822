import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import RichTextRenderer from '~/components/Content/RichText';

export const WhyAugust = ({ section }) => {
  const [active, setActive] = useState(section?.detailSections?.[0].displayName);
  return (
    <Flex
      sx={{
        px: ['1.5rem', '8rem'],
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '4rem',
      }}
    >
      <Flex sx={{ maxWidth: '130rem', width: '100%', flexDirection: 'column' }}>
        <Text
          variant="text.leadHeading"
          sx={{
            fontSize: '3.6rem',
            textAlign: 'center',
            mb: '1rem',
            p: {
              my: 0,
            },
          }}
        >
          <RichTextRenderer richText={section.heading} />
        </Text>
        <Text
          sx={{
            textAlign: 'center',
            fontSize: '1.6rem',
            fontWeight: '700',
            lineHeight: '150%',
            p: {
              my: 0,
            },
          }}
        >
          <RichTextRenderer richText={section.subheading} />
        </Text>
        <Flex
          sx={{
            border: '1px solid #CF2029',
            borderRadius: '1rem',
            width: '100%',
            flexDirection: 'column',
            mt: '2rem',
            minHeight: ['52rem', '52rem', '70rem'],
            maxHeight: ['52rem', '52rem', '70rem'],
            backgroundColor: 'lightPurple',
          }}
        >
          <Flex
            sx={{
              display: ['flex', 'flex', 'none'],
              borderBottom: '1px solid #CF2029',
              maxHeight: '5rem',
              minHeight: '5rem',
              width: '100%',
            }}
          >
            {section.detailSections.map((detail, index) => {
              const activeDetail = detail.displayName === active;
              return (
                <Flex
                  sx={{
                    width: '50%',
                    borderRight: index === 0 ? '1px solid #CF2029' : '',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: activeDetail ? 'alizarinCrimson' : '',
                    color: activeDetail ? '#fff' : 'alizarinCrimson',
                    borderRadius: index === 0 ? '1rem 0 0 0' : '0 1rem 0 0',
                  }}
                  onClick={() => {
                    setActive(detail.displayName);
                  }}
                >
                  <Text sx={{ fontSize: '1.3rem', fontWeight: 700, textTransform: 'uppercase' }}>
                    {detail.displayName}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            sx={{
              p: ['2rem', '2rem', '4rem min(8.5rem,5%)'],
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {section.detailSections.map((detail, index) => {
              const activeDetail = detail.displayName === active;
              return (
                <Flex
                  sx={{
                    flexDirection: ['column', 'column', 'row'],
                    justifyContent: 'space-between',
                    display: [
                      `${activeDetail ? 'flex' : 'none !important'}`,
                      `${activeDetail ? 'flex' : 'none !important'}`,
                      'flex !important',
                    ],
                    minHeight: ['100%', '100%', '32rem'],
                    maxHeight: ['100%', '100%', '50rem'],
                    alignItems: 'center',
                  }}
                >
                  <Flex
                    sx={{
                      order: [1, 1, index === 0 ? 1 : 2],
                      width: ['100%', '100%', '50%'],
                      justifyContent: ['center', 'center', 'flex-start'],
                    }}
                  >
                    <GatsbyImage
                      image={detail.mainImage.gatsbyImageData}
                      imgStyle={{
                        maxHeight: 'min(24rem, 100%)',
                        minHeight: 'min(22rem, 100%)',
                        objectFit: 'contain',
                      }}
                      style={{ maxHeight: '24rem', height: '50%' }}
                    />
                  </Flex>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: ['center', 'center', 'flex-start'],
                      order: [2, 2, index === 0 ? 2 : 1],
                      width: ['100%', '100%', '50%'],
                    }}
                  >
                    <Text
                      sx={{
                        display: ['none', 'none', 'block'],
                        fontSize: '2.4rem',
                        fontStyle: 'italic',
                        fontWeight: '800',
                        lineHeight: 'normal',
                      }}
                    >
                      {detail.displayName}
                    </Text>
                    <Box
                      sx={{
                        fontSize: ['1.3rem', '1.5rem', '2rem'],
                        maxWidth: '50rem',
                        minWidth: '34rem',
                        ul: {
                          pl: 0,
                        },
                        li: {
                          display: 'flex',
                          alignItems: 'center',
                          listStyle: 'none',
                          mb: '1rem',
                          '&:before': {
                            content: '""',
                            background:
                              'url(https://images.ctfassets.net/imbw84wm8wpn/56VpfBvxv9WMYLlP20QN1R/919d1d8bc1df44c3df51dffe110503c0/check_circle.png) no-repeat left center',
                            width: '2.4rem',
                            height: '2.4rem',
                            display: 'block',
                            flexShrink: 0,
                            backgroundSize: 'contain',
                            mr: '1rem',
                            mt: '0.25rem',
                          },
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: detail?.body?.childMarkdownRemark?.html,
                      }}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import HeroSection from '~/components/Pages/Splash/Sections/Hero';
import SampleBoxMarquee from '~/components/Generic/SampleBoxMarquee';
import GiftModal from '~/components/Pages/Splash/GiftModal';
import { ValueProp } from './Sections/ValueProp';
import { HomeReviews } from './Sections/HomeReviews';
import SubscriptionSection from './Sections/Subscription';
import { configureFatMedia } from '~/utils/fat-media';
import ABC from '../../../assets/images/press-logos/ABC.svg';
import Allure from '../../../assets/images/press-logos/Allure.svg';
import Buzzfeed from '../../../assets/images/press-logos/BuzzFeed.svg';
import CBS from '../../../assets/images/press-logos/CBS.svg';
import FastCompany from '../../../assets/images/press-logos/FastCompany.svg';
import Forbes from '../../../assets/images/press-logos/Forbes.svg';
import NewYorkTimes from '../../../assets/images/press-logos/NewYorkTimes.svg';
import Today from '../../../assets/images/press-logos/Today.svg';
import Vouge from '../../../assets/images/press-logos/Vogue.svg';
import Climate from '../../../assets/images/press-logos/Climate.svg';
import Plastic from '../../../assets/images/press-logos/Plastic.svg';
import Planet from '../../../assets/images/press-logos/Planet.svg';
import HomeScreenBoxes from './Sections/HomeScreenBoxes';
import { WhyAugust } from './Sections/WhyAugust';
import { ClimateMarquee } from '~/components/Generic/ClimateMarquee';

const Splash = ({ page }) => {
  const [giftModalOpen, setGiftModalOpen] = useState(false);

  configureFatMedia({ pageType: 'START' });

  useEffect(() => {
    if (typeof window === 'undefined') return () => {};
    const queryString = window.location.search.split('=');
    const giftCardRoute = window.location.pathname.split('/')[1];

    if (queryString && queryString[0] === '?tli')
      localStorage.setItem('creatorIQid', queryString[1]);
    if (giftCardRoute === 'redeemGiftCard') setGiftModalOpen(true);

    return () => {};
  }, []);

  const valueSection = page.sections.find((section) => section.name.includes('value'));
  const reviewSection = page.sections.find((section) => section.name.includes('review'));
  const subscriptionSection = page.sections.find((section) =>
    section.name.includes('subscription')
  );
  const whyAugustSection = page.sections.find((section) => section.name.includes('Why August'));

  const logosAndLinksPress = [
    {
      url: 'https://www.today.com/tmrw/august-new-period-brand-wants-eliminate-stigma-shame-t199796',
      svg: <Today />,
    },
    {
      url: 'https://www.nytimes.com/2022/01/20/well/sustainable-period-products.html',
      svg: <NewYorkTimes />,
    },
    {
      url: 'https://www.vogue.com/article/how-to-make-your-period-more-planet-friendly',
      svg: <Vouge />,
    },
    {
      url: 'https://www.forbes.com/sites/traversjohnson/2023/03/31/how-a-gender-affirming-period-care-brand-went-from-tiktok-to-target/?sh=6fe588c64390',
      svg: <Forbes />,
    },
    {
      url: 'https://abcnews.go.com/US/companies-making-single-period-products-sustainable/story?id=94481671',
      svg: <ABC />,
    },
    {
      url: 'https://www.cbsnews.com/video/changing-the-game-nadya-okamoto-on-period-care-equity-and-ending-the-stigma-around-periods/',
      svg: <CBS />,
    },
    {
      url: 'https://www.buzzfeednews.com/article/lorencecil1/best-tampons-that-dont-leak-review',
      svg: <Buzzfeed />,
    },
    {
      url: 'https://www.allure.com/story/august-liberare-periods-with-disabilities-campaign',
      svg: <Allure />,
    },
    {
      url: 'https://www.fastcompany.com/90874779/why-is-gen-z-always-talking-about-target-on-social-media',
      svg: <FastCompany />,
    },
  ];

  return (
    <Fragment>
      <Container variant="fullwidth" sx={{ overflow: 'hidden' }}>
        {giftModalOpen && <GiftModal />}
        <HeroSection hero={page.hero} />
        <HomeScreenBoxes />
        <WhyAugust section={whyAugustSection} />
        <Box sx={{ display: ['block', 'none', 'none'], mt: '4rem', mb: '4rem' }}>
          <div data-publish-id="tww8optyljbiq" className="tolstoy-stories" />
        </Box>
        <Container variant="fullwidth">
          <ClimateMarquee />
          <Box id="upperSticky" /> {/* show the build-a-box sticky after this */}
          <ValueProp section={valueSection} />
          <Box
            sx={{
              '.tolstoy-dots-container': {
                display: ['flex', 'none', 'none'],
              },
            }}
          >
            <div data-publish-id="l5fttbuxdc5eu" className="tolstoy-carousel" />
          </Box>
          <HomeReviews section={reviewSection} />
          <SampleBoxMarquee
            backgroundColor="alizarinCrimson"
            fontWeight="bold"
            logosAndLinks={logosAndLinksPress}
          />
        </Container>
        <Box id="lowerSticky" /> {/* hide the build-a-box sticky after this */}
        <SubscriptionSection section={subscriptionSection} />
      </Container>
    </Fragment>
  );
};

export default Splash;

/** @jsx jsx */
import { BLOCKS } from '@contentful/rich-text-types';
import { StaticImage } from 'gatsby-plugin-image';
import { keyframes } from '@emotion/react';
import { Link } from 'gatsby';
import { Container, Heading, Text, jsx, Flex, Box, Button } from 'theme-ui';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import RichTextRenderer from '~/components/Content/RichText';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';

const Swing = keyframes({
  from: { transform: 'rotateZ(-5deg)' },
  to: { transform: 'rotateZ(5deg)' },
});

const SubscriptionSection = ({ section }) => {
  const { openModal } = useBoxBuilder();

  return (
    <Container variant="fullwidth" sx={{ position: 'relative', textAlign: 'center' }}>
      <Box
        sx={{
          position: 'absolute',
          transition: 'all 0.3s ease-in-out',
          top: ['5rem', null, '2rem'],
          left: ['38%', 0],
          width: ['9rem', '11rem', '20rem'],
          height: ['12.5rem', '15rem', '27rem'],
          transformOrigin: 'top center',
          animation: `${Swing} 2s ease-in-out alternate infinite`,
        }}
      >
        <StaticImage src="../../../../../assets/images/Parachute-Box.png" objectFit="contain" />
      </Box>

      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          bottom: [0, '40%'],
          right: ['80%', '10%'],
          width: ['6.1rem', '10rem', '13rem'],
          height: ['10rem', '15rem', '21rem'],
          animation: `${Swing} 2s ease-in-out alternate-reverse infinite`,
        }}
      >
        <StaticImage src="../../../../../assets/images/Parachute-Liners.png" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          bottom: ['14%', '10%', '5%'],
          left: ['80%', '10%'],
          width: ['6.3rem', '10rem', '13rem'],
          height: ['10rem', '15rem', '21rem'],
          animation: `${Swing} 1.6s ease-in-out alternate-reverse infinite`,
        }}
      >
        <StaticImage src="../../../../../assets/images/Parachute-Pads.png" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          bottom: [0],
          right: ['75%', '10%', '20%'],
          width: ['6rem', '10rem'],
          height: ['10rem', '16rem'],
          animation: `${Swing} 2s ease-in-out alternate infinite`,
        }}
      >
        <StaticImage src="../../../../../assets/images/Parachute-Tampons.png" />
      </Box>

      <Box
        sx={{
          display: ['none', null, 'initial'],
          position: 'absolute',
          transformOrigin: 'top center',
          transition: 'all 0.3s ease-in-out',
          top: ['40%'],
          left: '95%',
          width: ['13rem', '20rem', '14rem'],
          height: ['18rem', '27rem', '22rem'],
          animation: `${Swing} 1.5s ease-in-out alternate infinite`,
        }}
      >
        <StaticImage src="../../../../../assets/images/Parachute-Box.png" />
      </Box>

      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pt: ['16rem', '16rem', '12rem', '16rem'],
          pb: ['12rem'],
        }}
      >
        <Link to="/products/box-builder/">
          <Heading
            as="h2"
            variant="leadHeading"
            sx={{
              fontSize: [13, 16, 19],
              mb: ['1rem', '2rem'],
              maxWidth: ['32rem', '39rem', '46rem', '68rem'],
              mx: 'auto',
              span: {
                display: 'block',
              },
            }}
          >
            <RichTextRenderer
              richText={section.heading}
              as="fragment"
              optionsOverride={{
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (_node, children) => <span>{children}</span>,
                },
              }}
            />
          </Heading>
        </Link>

        <Text
          as="p"
          sx={{ fontSize: [4, 7, 11], mb: '3rem', maxWidth: ['30rem', '40rem', '45rem', 'none'] }}
        >
          <RichTextRenderer
            as="fragment"
            richText={section.subheading}
            optionsOverride={{
              renderNode: {
                [BLOCKS.PARAGRAPH]: (_node, children) => <span>{children}</span>,
              },
            }}
          />
        </Text>

        <Button
          onClick={openModal}
          sx={{
            variant: 'buttons.ctaHighlight',
            fontSize: [6, 9],
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: section.buttonText.childMarkdownRemark.rawMarkdownBody,
            }}
          />

          <ArrowRight />
        </Button>
      </Flex>
    </Container>
  );
};

export default SubscriptionSection;

/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui';
import { Link } from 'gatsby';

const GiftModal = () => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 0,
        zIndex: 9999,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Flex
        sx={{
          width: ['90%', '50%', '50%'],
          height: 'auto',
          border: '1px solid red',
          flexDirection: 'column',
          backgroundColor: 'chatelle',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '24px',
          fontSize: [9, 10, 11],
          textAlign: 'center',
        }}
      >
        <Text sx={{ width: '90%', mb: '24px' }}>
          You have a gift card! Click below to create an account or login with the same email your
          gift card was sent to. The gift card will automatically apply as store credit during your
          next purchase or subscription renewal.
        </Text>

        <Link
          variant="tertiary"
          sx={{ textTransform: 'uppercase', fontWeight: 'bold', width: '125px' }}
          to="/account"
        >
          Redeem
        </Link>
      </Flex>
    </Flex>
  );
};
export default GiftModal;

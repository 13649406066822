import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import BoxBuilderPopUpContext from '~/contexts/BoxBuilderPopUpContext';
import CartContext from '~/contexts/CartContext';
import { HomeScreenBoxSection } from './HomeScreenBoxSection';
import YotpoStars from '~/components/Product/YotpoStars';
import { handleBundles, handlePreBuiltBoxes } from '~/utils/addToBag/addToBagWithTagging';

const HomeScreenBoxes = () => {
  const { data, bundleData, buildABoxImage } = useStaticQuery(graphql`
    query preBuiltBoxesCollectionQuery {
      data: allContentfulPreBuiltBoxes(
        sort: { fields: order }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        nodes {
          title
          order
          displayPrice
          boxImage {
            gatsbyImageData
          }
          defaultSubscriptionInterval {
            id
            name
            slug
            presentation
            length
            unit
          }
          tamponLight
          tamponRegular
          tamponSuper
          padDay
          padNight
          longNightPad
          liners
          description
          longDescription {
            childMarkdownRemark {
              html
            }
          }
          productDetails {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      bundleData: contentfulCollection(slug: { eq: "non-editable-bundles" }) {
        id
        name
        slug
        title
        products {
          ... on ContentfulProduct {
            ...ProductDetailsFragment
            mainImage {
              title
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 700)
            }
          }
          ... on ContentfulPreBuiltBoxes {
            title
          }
        }
      }
      buildABoxImage: contentfulAsset(title: { eq: "Build-A-Box-Thumbnail-Two" }) {
        title
        gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 700)
      }
    }
  `);
  const { nodes: preBuiltBoxes } = data;

  const boxContext = useContext(BoxBuilderPopUpContext);
  const [clickedButton, setClickedButton] = useState(null);
  const [bundleButtonLoading, setBundleButtonLoading] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState();

  const { addSubscription } = useCart();
  const [, setCartIsOpen] = useContext(CartContext);

  const subDiscount = 0.1;

  const clickHandler = async (product, order, isBundle) => {
    setClickedButton(order);
    if (isBundle) {
      setBundleButtonLoading(true);
      await handleBundles(product, addSubscription, setCartIsOpen, '_QA');
      setBundleButtonLoading(false);
    } else {
      handlePreBuiltBoxes(product, boxContext);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const queryBox = preBuiltBoxes.find(
      (box) => box.title.split(' ').join('_').toLowerCase() === urlParams.get('ABTC')
    );
    if (queryBox) {
      setTimeout(() => {
        handlePreBuiltBoxes(queryBox, boxContext);
      }, 1000);
    }
  }, [JSON.stringify(preBuiltBoxes)]);

  return (
    <>
      <Flex
        sx={{
          px: ['1.5rem', '8rem'],
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        id="bestSellers"
      >
        <Text
          variant="text.leadHeading"
          sx={{
            fontSize: '3.6rem',
          }}
        >
          BEST SELLERS
        </Text>
        <Flex
          sx={{
            mb: '4rem',
            justifyContent: 'center',
            height: '37.5px',
            '.yotpo-stars .rating-star': {
              fontSize: ['12px !important', '17.5px !important'],
            },
            '.yotpo-stars + a': {
              fontSize: ['1rem !important', '1.2rem !important'],
              marginTop: ['-3px', 'unset'],
            },
          }}
        >
          <YotpoStars slug="box-builder" />
        </Flex>
        <HomeScreenBoxSection
          boxList={[...bundleData.products, ...preBuiltBoxes]}
          subDiscount={subDiscount}
          clickedButton={clickedButton}
          showMoreInfo={showMoreInfo}
          setShowMoreInfo={setShowMoreInfo}
          clickHandler={clickHandler}
          bundleButtonLoading={bundleButtonLoading}
          buttonIsLoading={boxContext.buttonIsLoading}
          buildABoxImage={buildABoxImage}
        />
      </Flex>
    </>
  );
};

export default HomeScreenBoxes;

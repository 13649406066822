import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Spinner, Text } from 'theme-ui';
import { navigate } from 'gatsby';
import InfoIcon from '~/assets/images/icons/info.svg';
import BoxBuilderPopUpContext, { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import Close from '~/assets/images/icons/close_circle.svg';
import useWindowSize from '~/hooks/components/use-window-size';
import { prebuildBoxClick } from '~/utils/addToBag/addToBagWithTagging';

export const HomeScreenBoxcard = ({
  box = null,
  buildABox = false,
  title,
  id,
  gatsbyImageData,
  description,
  order,
  buttonIsLoading,
  price,
  subPrice,
  clickHandler = () => {},
  clickedButton,
  isBundle,
}) => {
  const { cart } = useCart();
  const [isFlipped, setIsFlipped] = useState(false);
  const [hover, setHover] = useState(false);
  const { width } = useWindowSize();
  const { openModal } = useBoxBuilder();
  const boxContext = useContext(BoxBuilderPopUpContext);

  const foundInCart = box?.sku
    ? cart?.lineItems?.find((item) => item.variant.sku === box?.sku)
    : null;

  const badgeText = buildABox ? 'Build Your Box' : isBundle ? 'Bundle' : 'Customizable';

  const detailText = buildABox
    ? 'A blank canvas to build your perfect box. Flexibility to modify, skip, and cancel anytime.'
    : isBundle
    ? 'Non-editable pre-made boxes at a discounted price. Flexibility to skip and cancel.'
    : 'Order as-is or customize once in cart. Flexibility to modify, skip, and cancel.';
  const cardSX = {
    position: 'absolute',
    flexDirection: 'column',
    width: '25%',
    minWidth: 'min(30rem, 80vw)',
    maxWidth: '30rem',
    height: '100%',
    minHeight: '30rem',
    maxHeight: '30rem',
    alignContent: 'center',
    backgroundColor: 'lightPurple',
    border: '1px solid #CF2029',
    color: 'alizarinCrimson',
    borderRadius: '20px',
    '-webkit-backface-visibility': 'hidden',
    backfaceVisibility: 'hidden',
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '25%',
        minWidth: 'min(30rem, 80vw)',
        maxWidth: '30rem',
        height: 'auto',
        minHeight: '30rem',
        maxHeight: '30rem',
        zIndex: 555,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          perspective: '1500px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transition: 'transform 0.8s',
            transformStyle: 'preserve-3d',
            transform: isFlipped ? 'rotateY(-180deg)' : null,
            cursor: "url('/images/blood.svg'), pointer",
          }}
        >
          {/* Front Card */}
          <Flex
            sx={{
              ...cardSX,
              zIndex: 1,
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                py: '2rem',
                px: '2rem',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  svg: {
                    width: '2rem',
                    height: '2rem',
                    cursor: "url('/images/blood.svg'), pointer",
                  },
                }}
              >
                <Text
                  sx={{
                    border: '1px solid #69102C',
                    p: '0.5rem 1rem',
                    borderRadius: '10rem',
                    color: 'darkTan',
                    fontSize: '1.2rem',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                  }}
                >
                  {badgeText}
                </Text>
                <InfoIcon onClick={() => setIsFlipped(true)} />
              </Flex>
              <GatsbyImage
                image={gatsbyImageData}
                imgStyle={{
                  objectFit: 'contain',
                  minHeight: '15rem',
                  maxHeight: width < 1024 ? '15rem' : '25rem',
                  marginTop: width < 1024 ? '1.5rem' : '0.5rem',
                }}
                style={{ height: '100%' }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              />
              <Button
                disabled={buttonIsLoading || foundInCart}
                onClick={(e) => {
                  e.stopPropagation();
                  if (buildABox) {
                    openModal();
                  } else {
                    clickHandler(box, order, isBundle);
                  }
                }}
                sx={{
                  position: 'absolute',
                  minWidth: '80%',
                  maxWidth: '80%',
                  bottom: '2rem',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  opacity:
                    hover || (clickedButton === order && buttonIsLoading) || width < 1024
                      ? foundInCart
                        ? 0.8
                        : 1
                      : 0,
                  transition: '0.2s all ease-in-out',
                  backgroundColor: 'canary',
                  px: ['2rem !important', '2rem !important', '2rem !important'],
                  py: '1rem !important',
                  my: 0,
                  borderRadius: '1rem',
                  fontWeight: 700,
                  zIndex: 500,
                }}
              >
                {buildABox ? (
                  <Text>Build Your Box</Text>
                ) : clickedButton === order && buttonIsLoading ? (
                  <Spinner data-testid="spinner" size="15" color="inherit" />
                ) : foundInCart ? (
                  <Text>ALREADY IN CART</Text>
                ) : (
                  <>
                    <Text
                      sx={{
                        pr: 'min(2rem, 5vw)',
                        mr: 'min(2rem, 5vw)',
                        borderRight: '1px solid #CF2029',
                      }}
                    >
                      Quick Add
                    </Text>
                    ${subPrice.toFixed(2)}
                  </>
                )}
              </Button>
            </Flex>
          </Flex>
          {/* Back Card Flipped */}
          <Flex
            sx={{
              ...cardSX,
              transform: 'rotateY(180deg)',
              zIndex: 5,
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                height: '100%',
                p: '2rem',
                'td, th': {
                  border: 'none!important',
                },
                tr: {
                  mr: '0.25rem',
                },
                'th, td': {
                  pr: '1rem',
                },
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'flex-end',
                  cursor: "url('/images/blood.svg'), pointer",
                  svg: {
                    width: '2rem',
                    height: '2rem',
                  },
                }}
                onClick={() => setIsFlipped(false)}
              >
                <Close />
              </Flex>
              <Text
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: '800',
                  letterSpacing: '0.15rem',
                  textTransform: 'uppercase',
                  mb: '1rem',
                }}
              >
                What’s in the box:
              </Text>
              {description?.childMarkdownRemark && (
                <Flex
                  sx={{
                    fontSize: '1.3rem',
                    fontWeight: '400',
                    lineHeight: '150%',
                    whiteSpace: 'break-spaces',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: description?.childMarkdownRemark?.html,
                  }}
                />
              )}
              <Text
                sx={{
                  fontSize: '1.3rem',
                  fontStyle: 'italic',
                  lineHeight: '150%',
                  position: buildABox ? '' : 'absolute',
                  bottom: '0',
                  maxWidth: buildABox ? '100%' : '80%',
                  mb: '2rem',
                }}
              >
                {detailText}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Flex
        sx={{
          py: ['0.5rem', '0.5rem', '1rem'],
          position: 'absolute',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: "url('/images/blood.svg'), pointer",
        }}
        onClick={(e) => {
          if (!isBundle && !buildABox) {
            e.preventDefault();
            prebuildBoxClick(box, boxContext, openModal);
          } else {
            isBundle
              ? navigate(`/products/${title.split(' ').join('-').toLowerCase()}`)
              : openModal();
          }
        }}
      >
        <Box>
          <Text
            sx={{
              textAlign: 'center',
              fontSize: '1.6rem',
              fontWeight: 800,
              textTransform: 'uppercase',
              lineHeight: '1.2rem',
            }}
          >
            {title}
          </Text>
        </Box>
        <Box sx={{ mt: '0.5rem' }}>
          <Text
            sx={{
              textAlign: 'center',
              fontSize: '1.5rem',
              fontWeight: 800,
              lineHeight: '1.2rem',
              color: 'darkTan',
              opacity: 1,
              textDecoration: buildABox ? '' : 'line-through',
              mr: '0.5rem',
            }}
          >
            {buildABox ? price : `$${price.toFixed(2)}`}
          </Text>
          <Text
            sx={{
              textAlign: 'center',
              fontSize: '1.5rem',
              fontWeight: 800,
              textTransform: 'uppercase',
              lineHeight: '1.2rem',
              color: buildABox ? 'darkTan' : 'discountGreen',
            }}
          >
            ${subPrice.toFixed(2)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

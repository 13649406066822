import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import useWindowSize from '~/hooks/components/use-window-size';

export const ValueProp = ({ section }) => {
  const { width } = useWindowSize();
  return (
    <Box
      sx={{
        py: ['3rem', '6rem', '9rem'],
        px: ['1.5rem', '5rem', width < 1200 ? '5rem' : '15.5rem'],
      }}
    >
      {section.detailSections.map((value, index) => {
        const even = (index + 1) % 2 === 0;
        const url = index === 4 ? '/traceability' : '/products/box-builder/';
        return (
          <Flex sx={{ justifyContent: 'center' }}>
            <Flex
              sx={{
                flexWrap: ['wrap', 'nowrap', 'nowrap'],
                mb: ['3rem', '8rem', 0],
                maxWidth: '144rem',
                width: '100%',
              }}
            >
              <Flex
                as={Link}
                to={url}
                sx={{
                  flexDirection: 'column',
                  alignContent: [even ? 'flex-end' : 'flex-start', 'flex-start', 'flex-start'],
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  textAlign: [even ? 'right' : 'left', 'left', 'left'],
                  width: ['100%', '60%', '60%'],
                  order: [0, even ? 0 : 1, even ? 0 : 1],
                }}
              >
                <Text
                  variant="leadHeading"
                  sx={{
                    width: ['75%', '75%', '100%', '100%'],
                    minWidth: ['25rem', '25rem', '41rem', '59rem'],
                    maxWidth: ['60rem', '35rem', '60rem', '60rem'],
                    fontSize: ['2.4rem', '3rem', '3.6rem'],
                    fontWeight: 900,
                    lineHeight: '150%',
                  }}
                >
                  {value.title}
                </Text>
                <Text
                  sx={{
                    width: ['75%', '100%', '100%'],
                    minWidth: ['25rem', '35rem', '40rem', '50rem'],
                    maxWidth: ['25rem', '50rem', '40rem', '50rem'],
                    mt: '0.5rem',
                    fontSize: ['1.3rem', '1.6rem', '2rem'],
                    lineHeight: ['2rem', '3rem ', '3rem '],
                    alignSelf: [even ? 'flex-end' : 'flex-start', 'unset', 'unset'],
                  }}
                >
                  {value.body.body}
                </Text>
              </Flex>
              <Flex
                sx={{
                  justifyContent: [
                    'center',
                    even ? 'flex-end' : 'flex-start',
                    even ? 'flex-end' : 'flex-start',
                  ],
                  alignItems: 'center',
                  width: ['100%', '60%', '60%'],
                  order: [1, even ? 1 : 0, even ? 1 : 0],
                }}
              >
                <Box
                  as={Link}
                  to={url}
                  sx={{
                    width: ['238px', '338px', '438px'],
                    height: ['238px', '338px', '438px'],
                    my: '4rem',
                    ml: ['4rem', even ? '4rem' : 0, even ? '4rem' : 0],
                    mr: ['4rem', even ? 0 : '4rem', even ? 0 : '4rem'],
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: ['7rem', '7rem', '10rem'],
                      position: 'absolute',
                      top: '-4rem',
                      right: even ? 'unset' : '-4rem',
                      left: even ? '-4rem' : 'unset',
                      zIndex: 10,
                    }}
                  >
                    <GatsbyImage
                      image={value.sectionIcon.gatsbyImageData}
                      alt={value.sectionIcon.title}
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: value.mainVideo.title.includes('SubscriptionBox')
                        ? '#B5AFC2'
                        : 'transparant',
                      borderRadius: '5px',
                      height: '100%',
                    }}
                  >
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      controls={false}
                      style={{
                        width: '100%',
                        borderRadius: '0.5rem',
                        boxShadow: even ? '10px 10px 0 0 #E6FC5C' : '-10px 10px 0 0 #E6FC5C',
                        objectFit: 'cover',
                        aspectRatio: '1/1',
                      }}
                    >
                      <source src={value.mainVideo.url} type="video/mp4" />
                    </video>
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};
